<template>
  <b-modal
    id="docLockModal"
    ref="docLockModal"
    title="Document Unlock"
    size="lg"
    hide-footer hide-header
  >
    <b-container v-if="!loading && reason === 'Service Requiring Attention'" class="pb-4">
      <h2>A service requires your attention in {{ document.jurisdiction }}.</h2>
      <div>
        <p>
          Please fill out the required information for this service in order to unlock the document.
        </p>
      </div>
    </b-container>
    <b-container v-else-if="!loading && reason === 'No Active Service'" class="pb-4">
      <h2>No Active Service in {{ document.jurisdiction }}</h2>
      <div>
        <p>
          You did not have active service in {{ document.jurisdiction }} when
          this document was received.
        </p>
        <p>You may pay ${{ lockData.price }} to view this document.</p>
      </div>
    </b-container>
    <b-container v-else-if="!loading && reason === 'Overdue Unpaid Invoices Exist'" class="pb-4">
      <h2>Invoice Payment Required</h2>
      <div>
        <p>
          The following invoices must be paid before you can view this document:
        </p>
        <p v-for="invoice in invoices" :key="invoice.id">
          Invoice #: {{ invoice.invoice_number }} - ${{ invoice.total }}
        </p>
      </div>
    </b-container>
    <b-container v-else-if="!loading && reason === 'Over Upload Limit'" class="pb-3">
      <h2>Document upload limit exceeded in {{ document.jurisdiction }}</h2>
      <div>
        <p>
          You've exceeded the document upload limit in {{ document.jurisdiction }},
          <br>
          which is where this document was received.
          <br>
          At the time of receiving this document, you were allowed
          <br>
          {{ lockData.data.limit }} documents and received {{ lockData.data.usage }}.
        </p>
      </div>
    </b-container>
    <b-container v-else-if="!loading && reason === 'Unlocked'" class="pb-4">
      <h2>Unlocked</h2>
      <div>
        <p>
          {{ lockData.description }}
        </p>
      </div>
    </b-container>
    <b-container v-else-if="!loading && reason === 'Law On Call Document Locked'" class="pb-4">
      <h2>Download Completed Legal Request</h2>
      <div>
        <p>
          The following invoice must be paid before you can download this Completed Legal Request.
        </p>
      </div>
    </b-container>
    <div v-if="!loading" style="text-align: center;">
      <router-link v-if="reason === 'Service Requiring Attention'" to="/services-requiring-attention">
        <b-button
          variant="primary"
          class="centered mt-2"
          aria-label="required information button"
        >
          Fill out the required information for the service
        </b-button>
      </router-link>
      <div v-else-if="canUpsellMailForwarding" class="button-container">
        <b-col>
          <b-button
            variant="outline-primary"
            aria-label="unlock invoice button"
            @click="payInvoices"
          >
            Unlock This Document Only - ${{ lockData.price }}
          </b-button>
          <div class="upgrade-header mt-3">
            <h3>Upgrade Options Available</h3>
            <span v-if="currentRaLimit > 0">Current Document Limit: {{ currentRaLimit }}</span>
          </div>
          <div class="keep-together">
            <b-button
              variant="primary"
              class="mt-2 btn-width"
              aria-label="upgrade to unique suite button"
              @click="upsellMailForwarding(mailForwardingOption)"
            >
              Upgrade to Unique Suite - ${{ mailForwardingOption.price }}{{ duration }}
            </b-button>
            <b-button
              id="tooltip-target-1"
              size="sm"
              variant="outline"
              class="btn-icon"
              aria-label="tooltip button"
            >
              <b-icon icon="question-circle-fill" />
            </b-button>
            <b-tooltip target="tooltip-target-1" triggers="hover" placement="top">
              <ul class="m-0 mr-3">
                <li>Unique Suite Number</li>
                <li>{{ totalUploadLimitToolTipText }}</li>
              </ul>
            </b-tooltip>
          </div>
          <div class="keep-together">
            <b-button
              variant="primary"
              class="mt-2 btn-width"
              aria-label="upgrade to virtual office button"
              @click="upsellMailForwarding(mailForwardingVirtualOffice)"
            >
              Upgrade to Virtual Office - ${{ mailForwardingVirtualOffice.price }}/Month
            </b-button>
            <b-button
              id="tooltip-target-2"
              size="sm"
              variant="outline"
              class="btn-icon"
              aria-label="tooltip button"
            >
              <b-icon icon="question-circle-fill" />
            </b-button>
            <b-tooltip target="tooltip-target-2" triggers="hover" placement="top">
              <ul class="m-0 mr-3">
                <li>Unique Suite Number</li>
                <li>Office Lease</li>
                <li>Unlimited Document Uploads</li>
                <li>Virtual Phone</li>
              </ul>
            </b-tooltip>
          </div>
        </b-col>
      </div>
      <b-button
        v-else-if="reason !== 'Unlocked'"
        variant="primary"
        class="centered mt-2"
        aria-label="view document button"
        @click="payInvoices"
      >
        View document for ${{ lockData.price }}
      </b-button>
    </div>
    <ct-centered-spinner v-else />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from './shared/CtCenteredSpinner'

export default {
  name: 'DocLockModal',
  components: {
    CtCenteredSpinner,
  },
  props: ['document', 'bus'],
  data () {
    return {
      loading: true,
      reason: '',
      lockData: null,
      unpaidInvoiceIds: [],
      canUpsellMailForwarding: false,
      mailForwardingOption: null,
      mailForwardingVirtualOffice: null,
    }
  },
  computed: {
    ...mapGetters('invoices', ['findAll']),
    ...mapGetters('checkout', ['productsByKind']),
    ...mapGetters('companies', ['activeRAServices', 'activeMFServices']),
    ...mapGetters('jurisdictions', ['findByName']),
    invoices() {
      return this.findAll(this.unpaidInvoiceIds)
    },
    totalUploadLimitToolTipText() {
      const total = this.calculateTotalUploadLimit()

      if (this.canUpsellMailForwarding) {
        return total === -1 ? 'Unlimited Document Uploads' : `${total} Total Document Uploads`
      }
      return ''
    },
    duration() {
      if (this.canUpsellMailForwarding) {
        switch (this.mailForwardingOption.duration) {
          case 1:
            return '/Month'
          case 12:
            return '/Year'
          default:
            return ''
        }
      }
      return ''
    },
    bothProductsPresent () {
      return this.mailForwardingOption && this.mailForwardingVirtualOffice
    },
    raServices() {
      return this.activeRAServices
    },
    mfServices() {
      return this.activeMFServices
    },
    simpleProducts() {
      return this.productsByKind('simple_product')
    },
    currentRaLimit() {
      return this.raServices.reduce((total, ra) => total + (ra.product.upload_limit || 0), 0)
    },
    hasValidDocLimit() {
      return this.currentRaLimit > 0
    },
    hasAvailableMailForwardingProducts() {
      return this.simpleProducts.filter(p => p.category === 'mail-forwarding').length > 1
    },
    hasActiveMailForwardingService() {
      return this.mfServices.length > 0
    },
    meetsMailForwardingUpsellCriteria() {
      return this.reason === 'Over Upload Limit' && !this.hasActiveMailForwardingService && this.hasAvailableMailForwardingProducts && this.hasValidDocLimit
    },
  },
  async mounted() {
    this.bus.$on('open', this.open)
  },
  methods: {
    ...mapActions('invoices', ['loadIfNotAvailableById']),
    ...mapActions('companies', ['loadActiveServices', 'loadById']),
    ...mapActions('checkout', ['loadSimpleProducts', 'setupContext']),
    async open(doc) {
      if (doc && doc.lock_data) {
        this.lockData = doc.lock_data
        this.unpaidInvoiceIds = []
        this.reason = this.lockData.reason || this.lockData.name
        this.formatPrice(this.lockData, 'price')
        await this.setupForMailForwardingUpsell()

        if (this.reason === 'Overdue Unpaid Invoices Exist') {
          this.unpaidInvoiceIds = this.lockData.data
          await this.loadIfNotAvailableById({ ids: this.unpaidInvoiceIds })
        }
        if (this.meetsMailForwardingUpsellCriteria) {
          await this.findUpsellProducts()
          if (this.bothProductsPresent) {
            this.canUpsellMailForwarding = true
          }
        }
        this.$refs.docLockModal.show()
      } else {
        this.$bvToast.toast('There was a problem. Please contact customer service.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        this.$refs.docLockModal.hide()
      }
      this.loading = false
    },
    payInvoices() {
      this.$refs.docLockModal.hide()
      if (this.reason === 'Overdue Unpaid Invoices Exist') {
        this.bus.$emit('payInvoices', this.unpaidInvoiceIds)
      } else {
        this.bus.$emit('payDocLock',
          {
            companyName: this.document.company_name,
            price: this.lockData.price,
            description: this.lockData.description,
            documentId: this.document.id,
            docLockType: this.lockData.doc_lock_type,
          }
        )
      }
    },
    upsellMailForwarding(product) {
      this.$refs.docLockModal.hide()

      this.bus.$emit('payUpsellMailForwarding',
        {
          companyName: this.document.company_name,
          product: product,
          document: this.document,
        }
      )
    },
    formatPrice(main, key) {
      if (main[key]) {
        main[key] = parseFloat(main[key]).toFixed(2)
      }
    },
    async setupForMailForwardingUpsell() {
      await this.loadById({ id: this.document.company_id })
      await this.loadActiveServices({ id: this.document.company_id })
      await this.setupContext({ company_id: this.document.company_id })
      await this.loadSimpleProducts()
    },
    async findUpsellProducts() {
      this.mailForwardingOption = this.findCheapestMailForwardingProduct()
      this.mailForwardingVirtualOffice = this.findVirtualOfficeProduct()
      if (this.bothProductsPresent) {
        await this.setJurisdictionOnProduct()
        this.formatPrice(this.mailForwardingOption, 'price')
        this.formatPrice(this.mailForwardingVirtualOffice, 'price')
      }
    },
    excludesVirtualOffice(productName) {
      const termsToExclude = ['virtual office', 'Virtual Office']
      return !termsToExclude.some(term => productName.includes(term))
    },
    filterConditions(product) {
      return product.category === 'mail-forwarding' && product.subcategory === 'cheap_open_and_scan' && this.jurisdictionSameAsDocument(product)
    },
    jurisdictionSameAsDocument(product) {
      const jurisdiction = this.findByName(this.document.jurisdiction)
      return product.schema?.[0].meta.default === jurisdiction.abbreviation
    },
    findCheapestMailForwardingProduct() {
      return this.simpleProducts.sort((a, b) => a.price - b.price).filter(p => this.filterConditions(p) && this.excludesVirtualOffice(p.name))[0]
    },
    findVirtualOfficeProduct() {
      return this.simpleProducts.sort((a, b) => a.price - b.price).filter(p => this.filterConditions(p) && !this.excludesVirtualOffice(p.name))[0]
    },
    async setJurisdictionOnProduct() {
      const jurisdiction = await this.findByName(this.document.jurisdiction)
      const data = { 'state': jurisdiction.abbreviation }
      this.mailForwardingVirtualOffice.data = data
      this.mailForwardingOption.data = data
    },
    calculateTotalUploadLimit() {
      const total = this.currentRaLimit

      if (total > 0) {
        return this.mailForwardingOption.upload_limit !== null ? total + this.mailForwardingOption.upload_limit : -1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
}
.upgrade-header {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.upgrade-header span {
  background: #fff;
  display: inline-block;
  padding: 10px 20px;
}
.upgrade-header:before {
  content: "";
  display: block;
  width: 100%;
  height:1px;
  background: #ccc;
  position: absolute;
  top: 50%;
  z-index: -1;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.btn-icon {
  padding: .25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: none;
}
.keep-together {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-width {
  width: 100%;
  max-width: 385px;
}
</style>
